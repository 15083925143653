import React, { PropsWithChildren, useContext, useState } from 'react';
import Navbar from "./Navbar";

type Props = {
  activeLink: string,
}

export const NavigationContext: any = React.createContext({ activeLinkState: '', setActiveLinkState: new Function()});


const NavigationWrapper = (props: PropsWithChildren<Props>) => {
  const { children, activeLink } = props;
  const [activeLinkState, setActiveLinkState] = useState({ activeLinkState: '', setActiveLinkState: new Function()});

  return (
    <div>
      <NavigationContext.Provider value={{ activeLinkState, setActiveLinkState }}>
        <Navbar activeLink={activeLink} />

        {children}
      </NavigationContext.Provider>
    </div>
  );
}

export default NavigationWrapper;