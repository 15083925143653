import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle<any>`
  ::-webkit-scrollbar {
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  
  html {
    box-sixing: border-box;
  }

  body {
    max-width: 100vw !important;
    min-height: 100vh !important;
    overflow-x: hidden !important;
    font-size: 16.5px;
    font-weight: 400;
    line-height: 1.5rem;
    color: black;
    background-color: white;
    text-align: left;
    height: 100% !important;
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input, textarea {
    font-family: 'Manrope', sans-serif;
  }

  h1 {
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  h2, h3 {
    font-weight: 700;
  }

  h2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: gray;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin: 0;
    margin-bottom: 15px;
  }

  h6 {
    font-size: 1rem;
    line-height: 1rem;
  }

  #root {
    overflow: hidden;
  }
`;

export default GlobalStyles