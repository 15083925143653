import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import GlobalStyles from './GlobalStyles';
import { Helmet } from "react-helmet";
import loadable from '@loadable/component';
import styled from 'styled-components';
import { AnimatedSwitch, spring } from 'react-router-transition';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Logo from './static/img/KING_ICO.png';
import NavigationWrapper from './components/NavigationWrapper';

const Home = loadable(() => import('./routes/Home'));
const About = loadable(() => import('./routes/About'));
const Privacy = loadable(() => import('./routes/Privacy'));
const Gallery = loadable(() => import('./routes/Gallery'));
const Contact = loadable(() => import('./routes/Contact'));
const Services = loadable(() => import('./routes/Services'));
const ErrorPage = loadable(() => import('./routes/ErrorPage'));

const AnimatedSwitchWithStyles = styled(AnimatedSwitch)`
  & > div {
    width: 100%;
    position: absolute;
  }
`;

function ScrollToTop({ history }: any) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

const ScrollToTopWrapper = withRouter(ScrollToTop);

function mapStyles(styles: any) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale}) translateX(${styles.translateX}vw)`,
  };
}

function bounce(val: any) {
  return spring(val, {
    stiffness: 150,
    damping: 20,
  });
}

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 1,
    translateX: 10,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(1),
    translateX: -10,
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
    translateX: 0,
  },
};

const App = () => {
  return (
    <Router>
      <Helmet defaultTitle="King's Constructions ⁠— Official Website">
        <meta 
          name="description" 
          content="Kings Constructions is a Canberra based and family owned business that specialises in existing home renovations and
double glazing upgrades.
              
At Kings Constructions we believe in going that extra mile, delivering high end quality work by experienced
and qualified tradesmen. We pride ourselves on our service and attention to detail, ensuring our customers
are understood and that the desired work is completed to the highest standard and exceeds all customer
expectations.
          "
        />

        <meta 
          name="author"
          content="Dion Misic"
        />

        <meta 
          name="keywords" 
          content="Kings, Constructions, Canberra, Carpentry, King's, Kings Constructions, King's Constructions, Builder"
        />
        
        <link 
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@500;700;800&display=swap" 
          rel="stylesheet"
        />

        <link 
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap" 
          rel="stylesheet"
        />

        <link rel="icon" type="image/png" href={Logo} sizes="16x16" />
      </Helmet>

      <GlobalStyles/>
      <ScrollToTopWrapper/>

      <NavigationWrapper activeLink=''>
        <AnimatedSwitchWithStyles
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
        >
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/gallery' component={Gallery} />
          <Route path='/services/:category/:img_key' component={Gallery} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/services' component={Services} />
          <Route path='/error' component={ErrorPage} status={404} />
          <Redirect from='*' to='/error' />
        </AnimatedSwitchWithStyles>
      </NavigationWrapper>
    </Router>
  );
}

export default App;
