import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../Colors';

type Props = { openMenu: Function, showMenu: boolean };

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

type HamburgerContainer = {
  rotated: boolean,
}

const Bar = styled.div`
  width: 40px;
  height: 2px;
  background-color: black;
  margin-top: 7px;
  margin-bottom: 7px;
  transition: 0.4s linear;
  transform: rotate(0deg);
`;

const Container = styled.div<HamburgerContainer>`
  &:hover ${Bar} {
    background-color: ${colors.main};
  }

  ${props => props.rotated && `
    ${Bar} {
      background-color: white;
    }

    ${Bar}:nth-child(1) {
      background-color: white;
      transform: rotate(45deg);
    }
    
    ${Bar}:nth-child(2) {
      background-color: white;
      transform: rotate(-45deg);
    }
    
    ${Bar}:nth-child(3) {
      background-color: white;
      opacity: 0;
    }
  `}
`;

const HamburgerMenu = (props: Props) => {
  const { openMenu, showMenu } = props;
  const [rotated, setRotated] = useState(false);

  const onMenuOpen = () => {
    setRotated(!rotated);
    openMenu();
  }

  useEffect(() => {
    setRotated(showMenu);
  });

  return (
    <Wrapper onClick={onMenuOpen} >
      <Container rotated={rotated}>
        <Bar/>
        <Bar/>
        <Bar/>
      </Container>
    </Wrapper>
  );
}

export default HamburgerMenu;