const config = {
  siteLinks: [
    { name: "Home", url: "/" },
    { name: "About", url: "/about" },
    { name: "Services", url: "/services" },
    { name: "Gallery", url: "/gallery" },
    { name: "Contact", url: "/contact" },
    { name: "Privacy", url: "/privacy" },
  ]
}

export default config;