import React, { useState, useContext } from 'react';
import HamburgerMenu from '../components/HamburgerMenu';
import NormalLogo from '../static/img/KINGBW.png';
import WhiteLogo from '../static/img/KINGWHITE.png';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { colors } from '../Colors';
import { Logo } from '../ResuableStyles';
import config from "../Config";
import { device } from "../Devices";
import { NavigationContext } from '../components/NavigationWrapper';
import { Helmet } from 'react-helmet';

const Inner = styled.div`
  width: 75vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  animation: fadeIn 1s ease-in-out;
`;

const MenuInner = styled(Inner)`
`;

const MenuContainer = styled(Inner)<Menu>`
  height: auto;
  justify-content: flex-end;
  text-align: right;
  color: ${colors.accent};
  display: none;

  ${props => props.showMenu && `
    display: block; 
  `};
`;

type Menu = {
  showMenu?: boolean
}

const MenuWrapped = styled.div<Menu>`
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: 0.5s ease-in-out;
  flex-direction: column;
  overflow: hidden;
  height: 100px;
  border-bottom: 15px solid ${colors.backgroundAccent};

  ${props => props.showMenu && `
    height: 100vh;
    background-color: #502c82;
    color: #895acc;

    ${InnerMenu} {
      visibility: visible;
      opacity: 1;
    }

    h5 {
      font-size: 0.95em;
    }
  `};
  
`;

const InnerMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s linear;
`;

type Props = { activeLink: string };

const Navbar = (props: Props) => {
  const { activeLink } = props;

  const context: any = useContext(NavigationContext);
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(!showMenu);

  const closeMenu = (name: string) => { 
    setShowMenu(false); 
    context.setActiveLinkState(name);
  }

  return (
    <MenuWrapped showMenu={showMenu}>
      <Helmet>
        <title>{`King's Constructions ⁠— ${context.activeLinkState}`}</title>
      </Helmet>

      <MenuInner>
        <Link to={'/'}>
          <Logo src={showMenu ? WhiteLogo : NormalLogo} />
        </Link>

        <HamburgerMenu showMenu={showMenu} openMenu={openMenu} />
      </MenuInner>
      
      <MenuContainer showMenu={showMenu}>
        <InnerMenu>
          —

          <ul>
          { 
            config.siteLinks.map((link, idx) => 
              <MenuItem 
                onClick={() => closeMenu(link.name)}
                key={idx} 
                {...link} 
                activeLink={context.activeLinkState} 
              />)
          }
          </ul>

          <h5>
            © Kings Constructions — {new Date().getFullYear()}. <br />
            ABN: 46621852373
          </h5>
        </InnerMenu>
      </MenuContainer>
    </MenuWrapped>
  );
}

type MenuItemProps = {
  name: string,
  url: string,
  activeLink: string,
  onClick: any,
}

type NavLinkProps = {
  activelink: string,
}

const NavLink = styled(Link)<NavLinkProps>`
  text-decoration: none;
  list-style-type: none;
  font-size: 4em;
  letter-spacing: -1px;
  font-weight: 700;
  color: ${props => props.activelink};
  line-height: 0.95em;
  transition: 0.3s ease;
  text-transform: uppercase;
  font-family: 'Roboto Condensed',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

  @media ${device.tablet} {
    font-size: 3em;
  }

  &:hover {
    cursor: pointer;
    color: white;
    text-decoration: line-through;
  }
`;

const MenuItem = (props: MenuItemProps) => {
  const { url, name, activeLink, onClick } = props;
  let activelink = (activeLink === name) ? 'white' : colors.accent;

  if (activeLink === 'Official Website' && name === 'Home') {
    activelink = 'white';
  }

  return (
    <NavLink onClick={onClick} activelink={activelink} to={url}>
      <li>{name}</li>
    </NavLink>
  )
}

export default Navbar;