import styled from "styled-components";
import { Link } from 'react-router-dom';
import { colors } from "./Colors";

type Inner = {
  justifyContent?: string,
}

export const Inner = styled.div<Inner>`
  width: 75vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(props: any) => props.justifyContent || 'flex-start' };
`;

type Container = {
  topOfPage?: boolean,
}

export const Container = styled.div<Container>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.topOfPage && `
    margin-top: 100px;
    background-color: ${colors.backgroundAccent};
    padding: 65px;
  `}
`;

export const ContainerWithPadding = styled(Container)`
  padding: 35px;
`;

export const Logo = styled.img`
  width: 185px;
  transition: 0.3s ease-in-out;
  display: inherit;
  text-align: left;
`;

export const WhiteLink = styled(Link)`
  color: white;
  font-size: 1.3em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: 400;
  display: flex;

  svg {
    fill: white;
    height: 1.3em;
  }

  &:hover {
    opacity: 0.9;
    color: white;
  }
`;
